import React from 'react';
import * as styles from './bottom-arrow.module.scss';

const BottomArrow = ({ isHidden, onCLick }) => {
  return (
    <div
      className={`${styles.arrow} ${styles.bounce} theme-arrow ${isHidden ? styles.disappear : ''}`}
      onClick={onCLick}
    />
  )
};

export default BottomArrow;