import React from 'react';
import * as styles from './side-navigation.module.scss';

const SideNavigation = ({ sectionNames, onMoveTo, active }) => {
  return (
    <ul className={`themed ${styles.wrapper}`}>
      {sectionNames.map((name, index) =>
        <li
          key={`sidenav-${index}`}
          onClick={() => onMoveTo(index + 1)}
          className={`${styles.item} ${active === index ? styles.active : ""} theme-text`}
        >
          <span className={`${styles.indicator} theme-nav-indicator`}/>
          <span className={styles.label}>{name}</span>
        </li>
      )}
    </ul>
  )
};

export default SideNavigation;