import React from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import SEO from "../components/seo";
import { SliceZones } from "../components/slices/slice-zones";
import { ColorThemeContextProvider } from "../contexts/colorThemeContext";
import LayoutFullPage from "../components/full-page/layout-full-page";
import CCLogoGray from "../images/cccluegrey.svg";
import CCLogoWhite from "../images/Claritycoat AF new.svg";

const FullpageSlider = ({ data, isPrismicPreview, pageContext: { lang } }) => {
  if (!data) {
    return null;
  }

  const document = data.prismicFullpageSlider;

  const meta = [
    {
      name: `description`,
      content: document.data.meta_description.text,
    },
    {
      property: `og:title`,
      content: document.data.meta_title.text,
    },
    {
      property: `og:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `twitter:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `og:image`,
      content: document.data.share_image.url,
    },
  ]

  return (
    <>
      <SEO title={document.data.title.text} meta={meta} canonical_link={document.data.canonical_link?.url}/>
      <ColorThemeContextProvider>
        <LayoutFullPage
          navigation
          bottomArrow
          isPrismicPreview={isPrismicPreview}
          disappearHeader={true}
          logo={document.data.body.length > 1 && document.data.body[0].primary.theme && document.data.body[0].primary.theme === 'light' ? CCLogoGray : CCLogoWhite }
          topNav={data.prismicHome?.data.menu_links}
          footerNav={data.prismicHome?.data.footer_links}
          blogListing={data.allPrismicBlogPost?.nodes}
          currentLanguage={lang}
        >
          <SliceZones slices={document.data.body} fullpage={true} />
        </LayoutFullPage>
      </ColorThemeContextProvider>
    </>
  )
};

export const query = graphql`
query FullpageSliderQuery($id: String, $lang: String) {
  prismicFullpageSlider(id: {eq: $id}, lang: {eq: $lang}) {
    _previewable
    id
    lang
    type
    url
    data {
      title {
        text
      }
      meta_title {
        text
      }
      share_image {
        url
      }
      meta_description {
        text
      }
      canonical_link {
        url
      }
      body {
        ... on PrismicFullpageSliderDataBodyFullpageCta {
          id
          slice_type
          primary {
            theme
            navigation_title
            cta_1_link {
              url
            }
            cta_1_title
            cta_2_link {
              url
            }
            cta_2_title
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            subheading {
              html
            }
          }
        }
        ... on PrismicFullpageSliderDataBodyFullpageFeatures {
          id
          slice_type
          primary {
            theme
            navigation_title
            cta_title
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            subheading {
              html
            }
            cta_link {
              url
            }
          }
          items {
            subtitle {
              text
            }
            title1 {
              text
            }
            image {
              url
            }
          }
        }
        ... on PrismicFullpageSliderDataBodyImageGallery1 {
          id
          slice_type
          primary {
            theme
            navigation_title
          }
          items {
            description {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            title1 {
              text
            }
          }
        }
        ... on PrismicFullpageSliderDataBodyImageGallery2 {
          id
          slice_type
          primary {
            theme
            navigation_title
            title1 {
              text
            }
            description {
              text
            }
          }
          items {
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
          }
        }
        ... on PrismicFullpageSliderDataBodyFullpage2Columns {
          id
          items {
            cta_title
            cta_link {
              url
            }
          }
          primary {
            theme
            navigation_title
            content {
              html
            }
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            image_position
            subheading {
              html
            }
          }
          slice_type
        }
        ... on PrismicFullpageSliderDataBodyFullpageImage {
          id
          primary {
            theme
            navigation_title
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
          }
          slice_type
        }
      }
    }
  }
  
  prismicHome(lang: {eq: $lang}) {
    data {
      menu_links {
          menu_link {
              url
              type
          }
          name {
            text
          }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`

export default withPrismicPreview(FullpageSlider);