import React, { useEffect, useState, useRef } from 'react';
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import ReactFullpage from "@fullpage/react-fullpage";
import SideNavigation from "./side-navigation";
import Footer from "./footer";
import { ColorThemeContextProvider } from "../../contexts/colorThemeContext";
import Header from "./header";
import BurgerMenu from "./burger-menu";
import TopArrow from "./top-arrow";
import BottomArrow from "./bottom-arrow";
import { defaultLanguage } from "../../../prismic-configuration";

const LayoutFullPage = ({
                          children,
                          navigation = false,
                          disappearHeader = false,
                          bottomArrow = false,
                          isPrismicPreview = false,
                          fullPageMobile = false,
                          logo,
                          links,
                          initColorTheme = 'light',
                          currentLanguage = defaultLanguage,
                          topNav = [],
                          footerNav = [],
                          blogListing = [],
                        }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  useEffect(() => {
    // open side nav to select language if no language is selected
    const saved = localStorage.getItem("lang");
    const initialValue = JSON.parse(saved);
    setIsOpenMenu(!initialValue);
  }, []);

  const fullPageSlider = useRef(null);

  const [isHiddenHeader, setIsHiddenHeader] = useState(false);

  const [pageApi, setPageApi] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);

  const [colorTheme, setColorTheme] = useState(initColorTheme);

  const {width} = useWindowSize();

  const sections = children.props.slices;

  if (isPrismicPreview && pageApi) {
    pageApi.destroy();
  }

  useEffect(() => {
    const onScrollListener = () => {
      if (disappearHeader) {
        if (window.scrollY >= 300){
          setIsHiddenHeader(true);
        } else {
          setIsHiddenHeader(false)
        }
      }
    };
    window.addEventListener("scroll", onScrollListener);

    return () => {
      window.removeEventListener("scroll", onScrollListener);
    };
  }, [])

  useEffect(() => {
    if (sections[currentSection]?.primary?.theme) {
      setColorTheme(sections[currentSection].primary?.theme)
    }
  }, [currentSection]);

  const isHidden = () => isHiddenHeader || (disappearHeader && currentSection !== 0);

  return (
    <div
      className={`full-page gotham-ssm ${colorTheme} ${fullPageMobile ? '' : 'fp-no-mobile'}`}
      style={{backgroundColor: "white"}}
    >
      <ColorThemeContextProvider colorTheme={colorTheme} setColorTheme={setColorTheme}>
        <Header
          openMenu={() => setIsOpenMenu(true)}
          logo={logo}
          links={[
            // {url: 'https://shop.claritycoat.com/', label: "Shop"},
          ]}
          isHidden={isHidden()}
        />
        <TopArrow
          isHidden={!isHidden()}
          moveToTop={width >= 768
            ? () => pageApi?.moveTo(1)
            : () => window.scrollTo({top: 0, behavior: 'smooth'})
          }
        />
        <BurgerMenu
          isOpen={isOpenMenu}
          onClose={() => setIsOpenMenu(false)}
          currentLanguage={currentLanguage}
          topNav={topNav}
          blogListing={blogListing}
        />
        {bottomArrow && <BottomArrow isHidden={currentSection !== 0} onCLick={() => pageApi?.moveTo(2)} />}
        <section>
          {children && (
            <>
              {navigation && width >= 768 && !isPrismicPreview && (
                <SideNavigation
                  sectionNames={children.props.slices.map((child, i) => child.primary?.navigation_title || `Section ${i}`)}
                  onMoveTo={pageApi?.moveTo}
                  active={currentSection}
                />
              )}

              {isPrismicPreview ? (
                <>
                  {React.Children.map(children, child => child)}
                  <section className="section fp-auto-height">
                    <Footer footerNav={footerNav} />
                  </section>
                </>
              ) : (
                <ReactFullpage
                  ref={fullPageSlider}
                  //fullpage options
                  licenseKey={'72C3BF85-26EC4EA5-A5312047-474A3E3A'}
                  scrollingSpeed={150} // Options here
                  responsiveWidth={fullPageMobile ? 0 : 768}
                  onLeave={(origin, destination) => {
                    setCurrentSection(destination.index)
                  }}
                  afterLoad={(origin, destination)  => {
                    const fullPage = document.querySelector('.fullpage-wrapper');
                    // set style for fullpage wrapper
                    fullPage.style.visibility = 'visible';
                  }}
                  render={({fullpageApi}) => {
                    if (!pageApi) {
                      setPageApi(fullpageApi);
                    }

                    return (
                      <ReactFullpage.Wrapper>
                        {React.Children.map(children, child => child)}
                        <section className="section fp-auto-height">
                          <Footer footerNav={footerNav} />
                        </section>
                      </ReactFullpage.Wrapper>
                    );
                  }}
                />
              )}
            </>
          )}
        </section>
      </ColorThemeContextProvider>
    </div>
  );
};

export default LayoutFullPage;